<!-- API设置 -->
<template>
    <el-form ref="form" :model="expressConfig" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('公司名')">
                    <el-input v-model="expressConfig.company" :placeholder="msg('请输入公司名')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('公司代码')">
                    <el-input v-model="expressConfig.companyCode" :placeholder="msg('请输入公司代码')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('登录账号')">
                    <el-input v-model="expressConfig.account" :placeholder="msg('请输入登录账号')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('登录密码')">
                    <el-input v-model="expressConfig.password" :placeholder="msg('请输入登录密码')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('API地址')">
                    <el-input v-model="expressConfig.url" :placeholder="msg('请输入API地址')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('API Key')">
                    <el-input v-model="expressConfig.appKey" :placeholder="msg('请输入API账号')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('API密钥')">
                    <el-input v-model="expressConfig.appSecret" :placeholder="msg('请输入API密钥')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('其他配置')">
                    <el-input v-model="expressConfig.other" :rows="5" :placeholder="msg('请输入其他配置')" size="small" type="textarea"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>
export default {
    name: "expressConfigEdit",
    components:{
    },
    data(){
        return {
            options:null,
        }
    },
    mounted(){
    },
    props:{
        expressConfig:Object,
    },
    methods:{
        init(){
        },
        onSubmit(){
            var _this = this;

            var url = null;
            if(this.expressConfig.id==null){
                url = this.$kit.api.expCompanyPage.createConfig;
            }else{
                url = this.$kit.api.expCompanyPage.updateConfig;
            }

            this.$axios.post(url,this.expressConfig)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg('update-ok'));
                        _this.$emit("close-dg", true);
                    }else{
                        _this.$message(result.msg || _this.msg('update-err'));
                    }
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>