<!-- 配送方式自定义面单 -->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="add()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <!-- <el-link :underline="false" @click="edit()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link> -->
                    <!-- <el-link :underline="false" @click="addDefault()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xitongshezhi3" font-size="27px;"/>
                            <span class="txt">{{msg('获取系统配置')}}</span>
                        </div>            
                    </el-link> -->
                    <el-link :underline="false" @click="edit()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-dayin2" font-size="27px;"/>
                            <span class="txt">{{msg('编辑配置')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editApi()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-API1" font-size="27px;"/>
                            <span class="txt">{{msg('配置API')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('配送方式')">
                        <el-input v-model="scope.query.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('清关')">
                        <el-input v-model="scope.query.creater" ></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="companyPages"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        <el-dialog title="添加配送方式" :visible.sync="addVisible" :fullscreen="isMinScreen" @opened="openAddDg"
            :close-on-click-modal="false" 
            width="600px" >
            <add :companyPage="companyPage" ref="dgAddRef" @close-dg="dgAddVisibleClose"/>
        </el-dialog>

        <el-dialog title="配置清关信息" :visible.sync="dgVisible" :fullscreen="true" @opened="openEditDg"
            :close-on-click-modal="false"  width="600px" :append-to-body="true"
        >
            <edit :companyPage="companyPage" ref="dgEditRef" @close-dg="dgVisibleClose"/>
        </el-dialog>

        <el-dialog title="API配置" :visible.sync="dgApiVisible" :fullscreen="isMinScreen" @opened="openApiDg"
            :close-on-click-modal="false" 
            width="600px" >
            <edit-api :expressConfig="expressConfig" ref="dgApiRef" @close-dg="dgApiVisibleClose"/>
        </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Add from "./ExpCompanyPageAdd.vue";
import Edit from "./ExpCompanyPageEdit.vue";
import EditApi from "./ExpConfigEdit.vue";

export default {
    name: 'companyPage',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit:Edit,
        Add:Add,
        EditApi:EditApi
    },
    mounted:function(){
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                rename: null,
                name: null, //exp_company这里兼容后台的字段名，
                creater:   null, //qing_guan字段，这里兼容后台的字段名，
            },
            showQuery: false,
            curr: null,
            columns: [],
            companyPages: [],
            dgVisible: false,
            dgApiVisible:false,
            companyPage:null,
            expressConfig:null,
            addVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'expCompany', title: this.msg('配送方式'), minWidth: 120, sortable: true},
                {field: 'qingGuan', title: this.msg('清关行'), minWidth: 120, sortable: true},
                {field: 'ownerName', title: this.msg('创建人'), minWidth: 120, sortable: true},
                {field: 'created', title: this.msg('创建时间'), minWidth:120, sortable: true},
            ]
        },
        list(){
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.expCompanyPage.list, param)
                .then((result) => {
                    if(result.status){
                        this.companyPages = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        openAddDg(){
            this.$nextTick(() => {
                this.$refs.dgAddRef.init();
            });
        },
        openEditDg(){
            this.$nextTick(() => {
                this.$refs.dgEditRef.init();
            });
        },
        openApiDg(){
            this.$nextTick(() => {
                this.$refs.dgApiRef.init();
            });
        },
        add(){
            this.companyPage = {
                id : null,
                expCompany : null,
            },
            this.addVisible = true;
        },
        edit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            let page = selectes[0];
            this.companyPage = {
                id : page.id,
                expCompany : page.expCompany,
                qingGuan:page.qingGuan,
            },
            this.dgVisible = true;
        },
        editApi(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }
            let page = selectes[0];
            let _this = this;
            this.$axios.post(_this.$kit.api.expCompanyPage.getConfig+"?id="+page.id)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        this.expressConfig = data;
                    }else{
                        this.expressConfig = {
                            id:null,
                            company:null,
                            companyCode:null,
                            account:null,
                            password:null,
                            url:null,
                            appKey:null,
                            appSecret:null,
                            other:null,
                            expCompanyPageId:page.id,
                        }
                    }
                    this.dgApiVisible = true;
                });

        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        dgVisibleClose(){
            this.dgVisible = false;
            this.list();
        },
        dgAddVisibleClose(){
            this.addVisible = false;
            this.list();
        },
        dgApiVisibleClose(){
            this.dgApiVisible = false;
            this.list();
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.msg('确定要删除选中的数据吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.expCompanyPage.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result > 0){
                        _this.$message(result.msg || _this.msg('删除成功'));
                        _this.list();
                    }else{
                        _this.$message(result.msg || _this.msg('删除失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
