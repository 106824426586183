<!-- 配送方式管理 -->
<template>
    <el-form ref="form" :model="companyPage" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="18">
                <el-form-item :label="msg('配送方式')">
                    <el-input v-model.trim="companyPage.expCompany" :placeholder="msg('请输入配送方式')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18">
                <el-form-item :label="msg('清关行')">
                    <el-input v-model.trim="companyPage.qingGuan" :placeholder="msg('请输入清关行')" size="small"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>

export default {
    name: "companyPageAdd",
    components:{

    },
    data(){
        return {
            options:null,
        }
    },
    mounted(){
    },
    props:{
        companyPage:Object,
    },
    methods:{
        init(){
        },
        onSubmit(){
            var _this = this;

            var url = this.$kit.api.expCompanyPage.create;

            this.$axios.post(url,this.companyPage)
                .then(function(result){
                    _this.$message(result.msg || _this.msg('update-ok'));
                    _this.$emit("close-dg", true);
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>