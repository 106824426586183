<template>
    <div>
        <div style="margin-bottom:10px;" v-if="showPrintBtn">
            <span style="display: inline-block;padding-left:5px; padding-right:15px;">
                <el-tooltip effect="dark" :content="msg('打印模板')" placement="top-start">
                    <el-button  size="mini" plain type="primary"  alt="打印模板" @click="doPrint()" >
                        <span class="el-icon-printer"></span>打印模板
                    </el-button>
                </el-tooltip>
            </span>
        </div>
        <div style="width:400px; height:400px; border: 1px solid #ddd;">
            <vue-easy-print tableShow ref="easyPrintRef">
                <template>
                    <span v-html="html"></span>
                </template>
            </vue-easy-print>
        </div>
    </div>
</template>
<script>
import vueEasyPrint from "vue-easy-print";

export default {
    name: 'PreviewPrintTemplate',
    components: {
        vueEasyPrint: vueEasyPrint
    },
    props: {
        html: String,
        showPrintBtn: Boolean,
    },
    methods: {
        //打印
        doPrint(){
            let _this = this;
            _this.$nextTick(()=>{
                _this.$refs.easyPrintRef.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrintRef.print();
                    }, 1500);
                });
            });
        },

        
    }
}

</script>