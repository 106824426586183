<!-- 配送方式管理 -->
<template>
    <div>
        <el-form ref="form" :model="companyPage" label-width="100px" style="margin:0 20px">
            <el-row>
                <el-col :xs="24" :sm="12">
                    <el-form-item :label="msg('配送方式')">
                        <el-input v-model.trim="companyPage.expCompany" :placeholder="msg('请输入配送方式')" size="small" :readonly="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <el-form-item :label="msg('清关行')">
                        <el-input v-model.trim="companyPage.qingGuan" :placeholder="msg('请输入清关行')" size="small" :readonly="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <div class="">
                <div style="margin: 10px 15px;">
                    <span style="font-weight: bold; display:inline-block; margin-right:20px;">打印模板设置</span>
                    <el-button type="primary" size="mini"  @click="addTemplate()">
                        <i class="el-icon-plus"></i><span>{{msg('新建模板')}}</span>
                    </el-button>
                </div>
                <el-table :data="templates" style="width: 100%">
                    <el-table-column prop="id" label="id" width="100"></el-table-column>
                    <el-table-column prop="name" label="模板名"></el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button @click="previewTemplate(scope.row)" type="text" size="small">查看</el-button>
                            <el-button @click="editTemplate(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="deleteTemplate(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div style="text-align:center; margin-top: 15px;">
                <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
                <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
            </div>

        </el-form>

        <!--编辑模板弹窗-->
        <el-dialog :title="templateDgTitle" :visible.sync="templateDgVisiable" :fullscreen="true" @opened="openedTemplateDg"
            :close-on-click-modal="false" :append-to-body="true">
            <print-setting ref="printSettingRef"  @close-dg="closeEditTemplateDg"
                :printSetting="editPrintSetting" 
                />
        </el-dialog>
        
        <!--预览打印模板-->
        <el-dialog title="预览打印模板" :visible.sync="previewDgVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false" :append-to-body="true" width="600px">
                <print-preview :html="previewHtml" :showPrintBtn="true"/>
        </el-dialog>
    </div>
</template> 

<script>

import PrintSetting from '../print/PrintSetting.vue';
import PrintPreview from "../print/PrintTemplatePreview";

export default {
    name: "companyPageEdit",
    components:{
        PrintSetting: PrintSetting,
        PrintPreview: PrintPreview
    },
    data(){
        return {
            options:null,
            templates: [],
            templateDgVisiable: false,
            previewDgVisiable: false,
            templateDgTitle: null,
            editPrintSetting: {
                id: null,
                printTemplateId: null,
                type: null,
                refId: null
            },
            previewHtml:"",
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
    },
    mounted(){
    },
    props:{
        companyPage:Object,
    },
    methods:{
        init(){
            this.getTemplates();
        },
        getTemplates(){
            let _this = this;
            _this.$axios.post(this.$kit.api.printSetting.listTemplate+"?refId="+_this.companyPage.id+"&type=0")
                .then((result) => {
                    _this.$message(result.msg);
                    if(result.data){
                        _this.templates = result.data;
                    }
                });
        },
        addTemplate(){
            this.editPrintSetting.id = null;
            this.editPrintSetting.printTemplateId = null;
            this.editPrintSetting.type = 0;
            this.editPrintSetting.refId = this.companyPage.id;

            this.templateDgTitle = "新增模板";
            this.templateDgVisiable = true;
        },
        previewTemplate(template){
            let _this = this;
            this.$axios.get(this.$kit.api.printSetting.template
                        +"?id="+template.id
                        +"&refId="+this.companyPage.id
                        +"&type="+0)
                    .then((result) => {
                        _this.$message(result.msg);
                        if(result.status){
                            _this.previewDgVisiable = true;
                            let template = result.data;
                            
                            let html = template.content;

                            if(template.variables){

                                for(let i=0; i<template.variables.length; i++){
                                    let temp = template.variables[i];
                                    var regexp = new RegExp("\\{\\s*"+temp.tmplKey+"\\s*\\}", "ig");
                                    if(temp.tmplValueType == 1){
                                        html = html.replace(regexp, _this.$kit.api.barcode+"?code="+temp.tmplDefValue);
                                    }else{
                                        html = html.replace(regexp, temp.tmplDefValue);
                                    }
                                }
                                _this.previewHtml = html;
                            }
                        }
                    });

        },
        editTemplate(template){
            this.editPrintSetting.id = null;
            this.editPrintSetting.printTemplateId = template.id;
            this.editPrintSetting.type = 0;
            this.editPrintSetting.refId = this.companyPage.id;

            this.templateDgTitle = "编辑模板";
            this.templateDgVisiable = true;
        },
        deleteTemplate(template){
            var _this = this;
            this.$confirm(this.msg('确定要删除选中的数据吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.printSetting.deleteTemplate
                    +"?id="+template.id
                    +"&refId="+_this.companyPage.id
                    +"&type=0"
                ).then((result) => {
                    _this.$message(result.msg);
                    if(result.data){
                        _this.getTemplates();
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //打开编辑模板弹窗
        openedTemplateDg(){
            let _this = this;
            _this.$nextTick(() => {
                _this.$refs.printSettingRef.init();
            });
        },
        //关闭编辑模板弹窗
        closeEditTemplateDg(){
            this.templateDgVisiable = false;
            this.getTemplates();
        },
        
        onSubmit(){
            var _this = this;

            var url = this.$kit.api.expCompanyPage.update;

            this.$axios.post(url,this.companyPage)
                .then(function(result){
                    _this.$message(result.msg || _this.msg('update-ok'));
                    _this.$emit("close-dg", true);
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>