<template>
<div class="page-box">
    <!-- style="position:fixed; top:0px;" -->
    <ctrl-box class="ctrl-box" :data="{}" v-reheight="ctrlBoxHeightChanged">
        <template>
            <div class="xgroup" >
                <el-link :underline="false" style="font-weight:bold;">
                    <div class="wrap" >
                        <span class="txt" >{{msg('订单管理')}}</span>      
                    </div>                
                </el-link>
            </div>
            <div class="xgroup">
                <el-link :underline="false" @click="showQuery = !showQuery">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-search" font-size="27px;"/>
                        <span class="txt" >{{msg('搜索')}}</span>      
                    </div>                
                </el-link>
                <el-link :underline="false" @click="doSummary()">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-zongshu" font-size="27px;"/>
                        <span class="txt">{{msg('统计')}}</span>
                    </div>
                </el-link>
            </div>
        </template>
    </ctrl-box>

    <div class="empty-fixed-box" v-bind:style="{height:emptyFixedBoxHeight}"></div>
    <!--查询区域-->
    <transition name="el-zoom-in-top">
        <query-box :query="query" v-show="showQuery" labelWidth="80px">
            <template v-slot:default="scope">
                <el-form-item :label="msg('订单编号')">
                    <el-input size="mini" clearable v-model="scope.query.code" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('快递单号')">
                    <el-input size="mini" clearable v-model="scope.query.innerExpCode" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('采购单号')">
                    <el-input size="mini" clearable v-model="scope.query.purchaseCode" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('订单状态')">
                    <el-select v-model="scope.query.status" multiple size="mini" clearable @change="changStatus">
                        <el-option v-for="d in dicts.stockStatusInOrder" 
                            :key="d.value" :label="d.label" :value="d.value">
                        </el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item :label="msg('采购状态')">
                    <el-select v-model="scope.query.purchaseStatus" size="mini" clearable>
                        <el-option label="已采购" :value="1"></el-option>
                        <el-option label="未采购" :value="2"></el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item :label="msg('快递')">
                    <el-select v-model="scope.query.isExpCode" size="mini" clearable>
                        <el-option label="有" :value="1"></el-option>
                        <el-option label="无" :value="2"></el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item :label="msg('链接')">
                    <el-select v-model="scope.query.isBuyLink" size="mini" clearable>
                        <el-option label="有" :value="1"></el-option>
                        <el-option label="无" :value="2"></el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item :label="msg('配送方式')">
                    <el-select v-model="scope.query.expCompany" size="mini" clearable>
                        <el-option v-for="d in dicts.expCompany" 
                            :key="d.value" :label="d.label" :value="d.value">
                        </el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item :label="msg('商品标题')">
                    <el-input size="mini" clearable v-model="scope.query.productTitle" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('规格')">
                    <el-input size="mini" clearable v-model="scope.query.productSpec" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('SKU')">
                    <el-input size="mini" clearable v-model="scope.query.sku" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('货号')">
                    <el-input size="mini" clearable v-model="scope.query.variationSku" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('店铺名')">
                    <el-input size="mini" clearable v-model="scope.query.shopName" ></el-input>
                </el-form-item>
                <el-form-item :label="msg('下单时间')">
                    <el-date-picker clearable
                        v-model="scope.query.Date" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="msg('最后出货时间')">
                    <el-date-picker clearable
                        v-model="scope.query.LastDate" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                
                <el-row>
                    <el-button type="primary" icon="el-icon-search" @click="getData(true)" size="mini">{{msg('搜索')}}</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                </el-row>
            </template>
        </query-box>
    </transition>
    
    <div class="table-wrap">
        <vxe-grid ref="dataTable" class="data-table" :height="docHeight"  
            stripe
            resizable border="full"
            show-overflow show-header-overflow 
            :data="datas"
            size="mini"
            :columns="columns"
            @cell-click="clickRow"
            :checkbox-config="{highlight:true, trigger: 'cell'}"
            :mergeCells="mergeCells"
            @scroll="scrollCallback"
        ></vxe-grid>

        <pagenator @page-change="handlerPageChange" :query="query" >
        </pagenator>
    </div>

</div>
</template>

<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'Order',
    components: {
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        this.buildColumns();
        this.getData();
    },
    data(){
        return {
            emptyFixedBoxHeight: '37px',
            query: {
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,

                code: null, //单号
                orderByType: null, //排序类型见StockDetailListQuery
                status: [],  //待备货、备货中、待打包、已打单、打包中，其中截货中要与其他4个条件相互排斥
                innerExpCode: null, //快递单号
                purchaseStatus: null,  //是否已采购
                isBuyLink: null,  //有/无采购链接
                purchaseCode: null, //采购单号
                sku: null, 
                variationSku: null, //货号
                expCompany: null, //配送方式
                isExpCode: null, //是否有快递单号
                shopName: null, //店铺名
                productSpec: null, //规格
                productTitle: null, //商品标题
                Date: [],
                LastDate: [],
            },
            columns: [],
            datas: [],
            showQuery: false,  //是否显示查询区域
            mergeCells: [],
        }
    },
    directives: {
      reheight: {
        bind(el, binding) {
          let height = '';
          function isReheight() {
            const style = document.defaultView.getComputedStyle(el);
            if (height !== style.height) {
              binding.value(style.height);
            }
            height = style.height;
          }
          el.__vueSetInterval__ = setInterval(isReheight, 300);
        },
        unbind(el) {
          clearInterval(el.__vueSetInterval__);
        }
      }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 260;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods: {
        
        //重置查询条件
        reset(){
            this.$kit.clearQuery(this.query);
        },
        ctrlBoxHeightChanged(height){
            this.emptyFixedBoxHeight = height;
        },
        //获取列表数据
        getData(refresh){
            let _this = this;
            
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            let param = this.$kit.rebuildQuery(this.query, ['Date', 'LastDate']);

            this.$axios.post(
                this.$kit.api.stockDetail.listByStock1, 
                param
            ).then((result) => {
                if(result.status){
                    let list = result.data.list;
                    //计算数量，变量每一条数据，然后找到这条数据的所有相同stockId的数据productQty然后求和
                    list.forEach(d => {
                        d['amount'] = (((d.productPrice || 0) * (d.productQty || 0))/100).toFixed(2) 

                        d['qty'] = list.filter(t => t.stockId === d.stockId)
                            .map(t => t.productQty || 0)
                            .reduce((x, y)=> x+y)
                    });
                    this.datas = list;
                    this.query.pageTotal = result.data.total;

                    this.$nextTick(() => {
                        this.mergeRow();
                    });
                }else{
                    this.$message(result.msg || this.msg('获取失败'));
                }
            });
        },
        //分页发生变化的回调函数
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getData();
        },
        //点击行，选中行
        clickRow({row,rowIndex, $event, column, columnIndex}){
            
        },
        //查询条件，状态选择框的只发生改变的回调函数
        changStatus(values){
            // console.log(values);

            var status = values[values.length-1];
            if(status == 80){
                // values = values.slice(index, index+1);
                this.query.status = [80]
            }else{
                let index = values.indexOf(80);
                if(index > -1){
                    this.query.status = values.filter(v => v!==80)
                }
            }

        },
        scrollCallback(scroll, event) {
            if (scroll.type != 'body' || !scroll.isY) {
                return;
            }
            this.mergeRow();
        },
        mergeRow(){
            var data = this.$refs.dataTable.getTableData();
            var visibleData = data.tableData;

            //页面刚刚初始化完成，无法获取到visiableData,所有默认去总数据里面取10条
            if (visibleData.length <= 1) {
                visibleData = data.visibleData.slice(0, 10);
            }
            //开始计算合并的位置
            var offset = this.$refs.dataTable.getRowIndex(visibleData[0]);

            const fields = ['code', 'detailQty', 'remark1', 'orderStatus', 'status','created', 'shopDayToShip', 'shopName'];
            var mergeCellResult = [];
            // if (fields.length == 0) {
            //     this.mergeCells = mergeCellResult;
            //     return;
            // }
            var allColumns = this.$refs.dataTable.getColumns();
            var columnNames = allColumns.map(x => x.property);
            for (var i = 0; i < fields.length; i++) {
                var property = fields[i];
                var rowIndex = 0;
                var colIndexes = this.findAllOccurrences(columnNames, property);
                if (colIndexes.length <= 0) {
                    continue;
                }
                while (rowIndex < visibleData.length) {
                    var row = visibleData[rowIndex];
                    const cellValue = row[property];
                    var rowspan = 1;
                    var startIndex = rowIndex;
                    if (cellValue != undefined && cellValue != null) {
                        let nextRow = visibleData[rowIndex + 1];
                        // while (nextRow && nextRow[property] == row[property]) {
                        while (nextRow && nextRow.stockId === row.stockId) {
                            rowIndex++;
                            nextRow = visibleData[rowIndex + 1];
                            rowspan++;
                        }
                    }
                    if (rowspan > 1) {
                        for (var p = 0; p < colIndexes.length; p++) {
                            mergeCellResult.push({row: offset + startIndex, col: colIndexes[p], rowspan: rowspan});
                        }
                        rowspan = 1;
                    }
                    rowIndex++;
                }
            }
            this.mergeCells=  mergeCellResult;
        },
        findAllOccurrences(arr, item){
            var subArr = [];
            for(var i = 0; i < arr.length; i++){
                if(arr[i] === item){
                    subArr.push(i);
                }
            }
            return subArr;
        },
        //构建列
        buildColumns(){
            
            this.columns = [
                {type: 'checkbox', minWidth: 60},
                {field: 'id', title: this.msg('ID'), minWidth: 100, sortable: true},
                {field: 'code', title: this.msg('订单编号'), minWidth: 100, sortable: true},
                {field: 'productThumb', title: this.msg('商品图'), minWidth: 60, 
                    slots: {
                        default: ({ row }) => {
                            let id = row.productThumb;
                            return id?[
                                <el-image  style="width: 60px; height: 60px" 
                                    src={this.$kit.api.imgView+id} 
                                    preview-src-list={[this.$kit.api.imgView+id]}
                                    ></el-image>
                            ]: [id];
                        },
                    }
                },
                {field: 'productTitle', title: this.msg('商品标题'), minWidth: 160, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<div style="padding: 6px 0px;   "><el-link href={this.$kit.api.shopeeDetail1+'?id='+row.id} target="_blank" style="font-size:12px;">
                                <span class="auto-break">{row.productTitle}</span>
                            </el-link></div>];
                        },
                    }
                },
                {field: 'productSpec', title: this.msg('规格'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<span class="auto-break">{row.productSpec}</span>];
                        },
                    }
                },
                {field: 'variationSku', title: this.msg('货号'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<span class="auto-break">{row.variationSku}</span>];
                        },
                    }
                },
                {field: 'sku', title: this.msg('SKU'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<span class="auto-break">{row.sku}</span>];
                        },
                    }
                },
                {field: 'productQty', title: this.msg('数量'), minWidth: 80, sortable: true},
                {field: 'productPrice', title: this.msg('单价'), minWidth: 80, sortable: true, 
                    slots: {
                        default: ({row}) => {
                            return ((row.productPrice || 0)/100).toFixed(2)
                        }
                    }
                },
                {field: 'amount', title: this.msg('总额'), minWidth: 80, sortable: true},
                {field: 'detailQty', title: this.msg('品类'), minWidth: 80, sortable: true},
                {field: 'expCompany', title: this.msg('配送方式'), minWidth: 120, sortable: true},
                {field: 'remark1', title: this.msg('备注'), minWidth: 135, 
                    slots: {
                        default: ({row}) => {
                            return (<div>row.remark1<br/>row.remark2<br/>row.shopSellerMsg<br/>row.note<br/>
                            </div>)
                        }
                    }
                },
                {field: 'orderStatus', title: this.msg('订单状态'), minWidth: 100, sortable: true, 
                    slots: {
                        default: ({row}) => {
                            let status = row.orderStatus;
                            if(status){
                                return [
                                    <el-tag type="primary">{status}</el-tag>
                                ];
                            }else{
                                return "";
                            }
                        }
                    }
                },
                {field: 'status', title: this.msg('货态'), minWidth: 100, sortable: true, 
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.stockStatus.find(d => d.value === row.status);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
                            ];
                        },
                    }
                },
                {field: 'purchaseStatus', title: this.msg('采购状态'), minWidth: 100, sortable: true, 
                    slots: {
                        default: ({row}) => {
                            return [<el-tag type={row.purchaseStatus == 1 ? "primary":"warning"}>{row.purchaseStatus == 1? '已采购':'未采购'}</el-tag>]
                        }
                    }
                },
                {field: 'purchaseCode', title: this.msg('采购单号'), minWidth: 110, sortable: true},
                {field: 'innerExpCode', title: this.msg('快递单号'), minWidth: 110, sortable: true},
                {field: 'hasLink', title: this.msg('链接'), minWidth: 80, sortable: true, 
                    slots: {
                        default: ({row}) => {
                            if(row.hasLink){
                                return <i class="el-icon-link"></i>
                            }else{
                                "";
                            }
                        }
                    }
                },
                {field: 'created', title: this.msg('下单时间'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<span class="auto-break">{row.created}</span>];
                        },
                    }
                },
                {field: 'shopDayToShip', title: this.msg('最后出货时间'), minWidth: 130, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [<span class="auto-break">{row.shopDayToShip}</span>];
                        },
                    }
                },
                {field: 'shopName', title: this.msg('店铺名'), minWidth: 130, sortable: true},
                
            ]

        },

    }

}

</script>

<style lang="scss" scoped>
.data-table::v-deep(.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell,
.vxe-table--render-default .vxe-footer--column.col--ellipsis>.vxe-cell,
.vxe-table--render-default .vxe-header--column.col--ellipsis>.vxe-cell){
    max-height:none;
}

.page-box{overflow-y:auto;}
.ctrl-box{position: absolute;z-index:99;width:100%;}
.empty-fixed-box{height:57.5px;}
.auto-break{
    word-break: break-all;
    white-space: pre-line;
}
</style>