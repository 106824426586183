<!--编辑自定义打印列表管理模板-->
<template>
    <div>
        <!--html编辑器-->
        <div :class="['html-editor', fullscreen?'full-editor':'']">
            <div class="editor-bar">
                <el-tooltip effect="dark" :content="msg('全屏')" placement="top-start">
                    <el-button circle size="mini" alt="全屏" @click="() => fullscreen=!fullscreen">
                        <span class="el-icon-copy-document"></span>
                    </el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :content="msg('提取变量')" placement="top-start">
                    <el-button circle size="mini" alt="提取变量" @click="extraKeys">
                        <span class="el-icon-c-scale-to-original"></span>
                    </el-button>
                </el-tooltip>
                <el-tooltip effect="dark" :content="msg('预览')" placement="top-start">
                    <el-button circle size="mini" alt="预览" @click="preview">
                        <span class="el-icon-view"></span>
                    </el-button>
                </el-tooltip>
                <span style="display: inline-block; padding-right:5px;">
                    <el-input size="mini" v-model="template.name" placeholder="模板名" style="width:160px;"></el-input>
                </span>
                <el-tooltip effect="dark" :content="msg('保存模板')" placement="top-start">
                    <el-button  size="mini" type="success" alt="保存模板" @click="save">
                        <span class="el-icon-check"></span>保存模板
                    </el-button>
                </el-tooltip>
            </div>
            <textarea ref="textarea" />
        </div>
        
        <el-row :gutter="20">
            <!--变量编辑-->
            <el-col :xs="24" :sm="12" v-show="vars && vars.length>0">
                <div style="padding: 10px;font-weight:bold; font-size:16px; color:#444">变量编辑</div>
                <el-row :gutter="20">
                    <el-col class="vars-list" :span="10">
                        <div  v-for="v in vars" :key="v.index" :class="['var-item', v.active?'active':'']"
                            @click="activeVarItem(v)"
                        >{{ v.tmplKey }}</div>
                    </el-col>
                    <el-col v-if="activeItem" :span="14" style=" border: 1px solid #ddd; padding: 8px 5px;">
                        <div class="active-item-cell">
                            <label class="my-label">变量名：</label>
                            <el-input size="mini" v-model="activeItem.tmplKey" :readonly="true"></el-input>
                        </div>
                        <div class="active-item-cell">
                            <label class="my-label">变量值：</label>
                            <!-- 
                            <el-select v-model="activeItem.tmplTableName" placeholder="请选择" size="mini">
                                <el-option v-for="op in varOptions" :key="op.name"
                                    :label="op.name" :value="op.table">
                                </el-option>
                            </el-select> 
                            -->
                            <el-select v-model="activeItem.tmplField" placeholder="请选择" size="mini" @change="tmplFieldChange">
                                <el-option v-for="op in varOptions" :key="op.name"
                                    :label="op.name" :value="op.field">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="active-item-cell">
                            <label class="my-label">值类型：</label>
                            <el-select v-model="activeItem.tmplValueType" @change="changeValueType"  placeholder="请选择" size="mini">
                                <el-option label="文本" :value="0"></el-option>
                                <el-option label="条形码" :value="1"></el-option>
                                <el-option label="二维码" :value="2"></el-option>
                                <el-option label="日期" :value="3"></el-option>
                                <el-option label="数字" :value="4"></el-option>
                            </el-select>
                        </div>
                        <div v-show="showFormatCell" class="active-item-cell">
                            <label class="my-label">显示格式：</label>
                            <el-input size="mini" v-model="activeItem.tmplTextFormat"></el-input>
                        </div>
                        <div class="active-item-cell">
                            <label class="my-label">默认值：</label>
                            <el-input size="mini" v-model="activeItem.tmplDefValue"></el-input>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <!--预览-->
            <el-col :xs="24" :sm="12" v-show="previewHtml">
                <div style="padding: 10px;font-weight:bold; font-size:16px; color:#444">预览</div>
                <print-preview :html="previewHtml" :showPrintBtn="true"/>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import PrintPreview from "./PrintTemplatePreview";
import "codemirror/lib/codemirror.css"
import "codemirror/addon/hint/show-hint.css"
require('codemirror/addon/hint/show-hint.js')
require('codemirror/addon/hint/xml-hint.js')
require('codemirror/addon/hint/html-hint.js')
require('codemirror/mode/xml/xml.js')
require('codemirror/mode/javascript/javascript.js')
require('codemirror/mode/css/css.js')
require('codemirror/mode/htmlmixed/htmlmixed.js');
require('codemirror/theme/idea.css')
const CodeMirror = require('codemirror/lib/codemirror.js');

export default {
    name: 'printSetting',
    components:{
        PrintPreview: PrintPreview
    },
    mounted:function(){
        this.initEditor()
    },
    props:{
        printSetting: Object
    },
    data(){
        return {
            template: {
                name: "新建打印模板", //模板名
                content: "<html></html>",
            },
            editor: null,
            fullscreen: false,
            options: {
                line:true,
                mode: 'text/html',
                tabSize: 2,
                lineNumbers: true,
                lineWrapping: false,
                extraKeys: {'Ctrl-Space': 'autocomplete'},
                theme: 'idea'
            },
            vars: [], //变量列表
            varOptions: [  //这里规定，当时数字的时候，表示截取小数点后的几位，当时日期的时候：默认yyyy-MM-dd
                {name: "订单编号", field: "code", table: "stock"},
                {name: "报关单号", field: "declare_code", table: "stock"},
                {name: "申报人名", field: "declare_user", table: "stock"},
                {name: "申报电话", field: "declare_tel", table: "stock"},
                {name: "申报地址", field: "declare_address", table: "stock_append"},
                {name: "托运重量", field: "weight_consign", table: "stock"},
                {name: "货物品名", field: "product_name", table: "stock"},
                {name: "申报数量", field: "declare_qty", table: "stock_append"},
                {name: "申报单价", field: "declare_price", table: "stock_append", format: "2"},  
                {name: "申报均值", field: "average", table: "stock_append", format: "2"},  
                {name: "订单金额", field: "amt_order", table: "stock", format: "2"},  
                {name: "建单时间", field: "created", table: "stock", format: "yyyy-MM-dd"},
                {name: "收件人", field: "receiver", table: "stock"},
                {name: "收件地址", field: "receiver_addr", table: "stock"},
                {name: "收件电话", field: "receiver_tel", table: "stock"},
                {name: "新竹區號", field: "xinzhuAddCode", table: "stock"},
            ], //预定义可选变量
            activeItem: null, //选中的要编辑的变量
            previewHtml: null,  //待预览的html
            showFormatCell: false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        //templateId: 模板id,如果存在则说明是编辑，需要从线上加载数据，并且初始化
        init(){  //初始化的时候，如果是编辑模板，则加载数据
            if(this.printSetting.printTemplateId){
                this.$axios.get(this.$kit.api.printSetting.template
                        +"?id="+this.printSetting.printTemplateId
                        +"&refId="+this.printSetting.refId
                        +"&type="+this.printSetting.type
                    ).then((result) => {
                        this.$message(result.msg);
                        if(result.status){
                            let template = result.data;
                            if(template.variables){
                                for(let i = 0; i<template.variables.length; i++){
                                    let t = template.variables[i];
                                    //手动添加index和active属性
                                    t["index"] = i;
                                    t["active"] = false;
                                }
                                this.vars = template.variables;
                            }
                            this.setEditorContent(template.content)
                            this.template = template;
                        }
                    });
            }else{
                this.vars = [];
                this.template = {
                    name: "新建打印模板", //模板名
                    content: "<html></html>",
                };
                this.setEditorContent('<html></html>');
            }
        },
        initEditor(){
            //初始化编辑器，需要传入，textarea文本域和options
            this.editor=CodeMirror.fromTextArea(
                this.$refs.textarea,
                this.options
            )

            this.editor.on('inputRead', ()=>{
                this.editor.showHint();
            })

            //编辑器赋值
            if(this.template.content){
                this.setEditorContent(this.template.content);
            }else{
                this.editor.setValue('')
            }

            //支持双向绑定
            this.editor.on("change", (editor)=>{
                this.template.content = editor.getValue();
                if(this.$emit){
                    this.$emit('input', this.template.content)
                }
            })
        },
        //提取变量名
        extraKeys(){
            if(!this.template.content){
                return;
            }
            var regexp = /\{(.+?)\}/g;
            var results = this.template.content.match(regexp);
            var tempVars = [];

            if(results){
                for(let i=0; i<results.length; i++){
                    var item = results[i];
                    let item1 = item.replace("{", "").replace("}", "").trim();
                    tempVars.push({
                        index: i+1,
                        tmplKey: item1,
                        tmplField: null,
                        tmplTableName: null,
                        tmplValueType: 0,  //0-文本，1-barcode, 2-qrcode, 3-日期, 4-数字，显示的位数
                        tmplTextFormat: "",
                        active: false,
                        tmplDefValue: item,  //默认值
                    });
                }
            }

            //合并，
            if(tempVars.length>0){
                if(this.vars && this.vars.length>0){
                    for(let i = 0; i<tempVars.length; i++){
                        let item = tempVars[i];
                        let existItem = this.vars.find(v => v.tmplKey == item.tmplKey);
                        if(existItem){
                            existItem.index = (i+1);
                            tempVars[i] = existItem;
                        }
                    }
                    
                }
                this.vars = tempVars;
            }else{
                this.vars = [];
            }

        },  
        //设置编辑器内容
        setEditorContent(code){
            setTimeout(()=>{
                if(!code){
                    this.editor.setValue("")
                }else{
                    this.editor.setValue(code)
                }
            }, 300);
        },
        //选中变量条目
        activeVarItem(item){
            for(let i=0; i<this.vars.length; i++){
                let temp = this.vars[i];
                if(temp.index == item.index){
                    item.active = true;
                }else{
                    temp.active = false;
                }
            }
            this.activeItem = item;
        },
        //变量类型选中变化事件
        changeValueType(val){
            if(val===3 || val===4){
                this.showFormatCell = true;
                if(val === 3){
                    if(!this.activeItem.tmplTextFormat || this.activeItem.tmplTextFormat.indexOf("MM") == -1){
                        this.activeItem.tmplTextFormat = "yyyy-MM-dd"
                    }
                }else if(val === 4){
                    if(!this.activeItem.tmplTextFormat || this.activeItem.tmplTextFormat.indexOf("MM")>-1){
                        this.activeItem.tmplTextFormat = "2"
                    }
                }

            }else{
                this.showFormatCell = false;
            }
        },
        //值发生变化后
        tmplFieldChange(val){
            let option = this.varOptions.find(vo => vo.field === val);
            if(option){
                this.activeItem.tmplTableName = option.table;
            }
        },
        //预览打印结果
        preview(){

            var html = this.template.content;
            if(!html){
                this.previewHtml = null;
                return;
            }

            if(this.vars && this.vars.length>0){
                for(let i=0; i<this.vars.length; i++){
                    let temp = this.vars[i];
                    var regexp = new RegExp("\\{\\s*"+temp.tmplKey+"\\s*\\}", "ig");
                    if(temp.tmplValueType == 1){
                        html = html.replace(regexp, this.$kit.api.barcode+"?code="+temp.tmplDefValue);
                    }else{
                        html = html.replace(regexp, temp.tmplDefValue);
                    }
                }
            }

            this.previewHtml = html;

        },
        //保存模板
        save(){
            var _this = this;
            
            var params = [];
            if(this.vars && this.vars.length>0){
                for(let i=0; i<this.vars.length; i++){
                    let temp = this.vars[i];
                    params.push({
                        id: temp['id'] || null,
                        tmplKey: temp.tmplKey,
                        tmplField: temp.tmplField,
                        tmplTableName: temp.tmplTableName,
                        tmplValueType: temp.tmplValueType,
                        temlTextFormat: temp.temlTextFormat || null,
                        tmplDefValue: temp.tmplDefValue || '',
                    });
                }
            }

            var data = {
                printTemplateId: this.printSetting.printTemplateId || null,
                type: this.printSetting.type,
                refId: this.printSetting.refId,
                template: {
                    id: this.printSetting.printTemplateId || null,
                    name:this.template.name,
                    content:this.template.content,
                    variables: params
                }
            }

            // console.log(data);
            
            var url = this.$kit.api.printSetting.createTemplate;
            if(this.printSetting.printTemplateId){
                url = this.$kit.api.printSetting.updateTemplate;
            }

            this.$axios.post(url,data)
                .then(function(result){
                    _this.$message(result.msg);
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                });

        }


    }
}
</script>

<style>
.editor-bar{
    background: white;
    line-height:300%;
}
.html-editor{
    width:100%;
    height:100%;
}
.html-editor .CodeMirror{
    width:100%;
    height:400px;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.html-editor.full-editor{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
}
.html-editor.full-editor .CodeMirror{
    height:calc(100% - 55px);
}
.html-editor.full-editor .editor-bar{
    padding:10px;
}
.vars-list{
    border:1px solid #ddd;
    max-height: 300px;
    overflow-y:auto;
}
.var-item{
    padding: 5px 8px;
    cursor:pointer;
}
.var-item:hover{
    color:#1989fa;
    background: #f8fcff;
}
.var-item.active{
    color:#1989fa;
    background: #f8fcff;
}
.active-item-cell{
    display:flex; align-items:center;
    padding: 5px 0px;
}
.my-label{
    width:70px;
    min-width:70px;
    text-align:right;
}
</style>
